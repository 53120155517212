import { Autocomplete, Popper, PopperProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import SearchNotFound from 'src/components/search-not-found';
import AutoInputBase from './AutoInputBase';
import OptionList from './OptionList';
// types
import { ClientsType } from 'src/redux/slices/BusinessSlice/businessSlice.d';
import { bgBlur } from 'src/utils/cssStyles';
// hooks
import useNavigateClient from '../services/useNavigateClient';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const StyledPopper = styled((props: PopperProps) => <Popper {...props} />)(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: 'calc(100% - 16px) !important',
  transform: 'none !important',
  '& .MuiAutocomplete-paper': {
    padding: theme.spacing(1, 0),
  },
  '& .MuiListSubheader-root': {
    '&.MuiAutocomplete-groupLabel': {
      ...bgBlur({ color: theme.palette.background.neutral }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: '48px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: 'block',
      border: `dashed 1px transparent`,
      borderBottomColor: theme.palette.divider,
      '&:last-of-type': {
        borderBottomColor: 'transparent',
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  setSearchQuery: (value: string) => void;
  setSelectedClient: (value: ClientsType | undefined) => void;
  clients: ClientsType[];
  searchQuery: string;
  loading: boolean;
};

export default function AutoCompleteBar({
  searchQuery,
  setSearchQuery,
  setSelectedClient,
  clients,
}: Props) {
  const { navigateClient, idLoading } = useNavigateClient();

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick(searchQuery);
    }
  };

  const handleClick = (name: string) => {
    const index = clients.findIndex((i) => i.name === name);
    if (index !== -1) {
      setSelectedClient(clients[index]);
    }
    setSearchQuery(name);
  };

  const handleNavigateClient = (client: ClientsType) => {
    navigateClient(client.id, () => {
      setSelectedClient(client);
    });
  };

  return (
    <Autocomplete
      PopperComponent={StyledPopper}
      disablePortal
      getOptionLabel={(client: ClientsType) => client.name}
      isOptionEqualToValue={(client: ClientsType, value: ClientsType) => client.id === value.id}
      loading={idLoading !== ''}
      noOptionsText={<SearchNotFound query={searchQuery} sx={{ py: 10 }} />}
      onChange={(e, v, r) => {
        if (r === 'clear') setSelectedClient(undefined);
      }}
      onInputChange={(_, value) => {
        setSearchQuery(value);
      }}
      options={clients}
      popupIcon={null}
      renderInput={(params) => <AutoInputBase handleKeyUp={handleKeyUp} params={params} />}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: ClientsType) => (
        <OptionList
          {...option}
          {...props}
          clientId={option.id}
          loading={idLoading === option.id}
          onClick={() => handleNavigateClient(option)}
        />
      )}
      sx={{ width: 1, height: 1 }}
    />
  );
}
