import { createContext } from 'react';
// type

export interface Actions {
  onSort?: (id: string | undefined, newOrder?: 'asc' | 'desc') => void;
  onSelectAllRows?: (checked: boolean) => void;
  onHideColumn?: (id: string) => void;
}

export interface ContextType {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  actions: Actions;
}

const defaultContextValues: ContextType = {
  headLabel: [],
  actions: {},
};

export default createContext<ContextType>(defaultContextValues);
