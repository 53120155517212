import { MenuItem, Typography } from '@mui/material';
import { useContext } from 'react';
// components
import SortItem from './SortItem';
// context
import Context from '../context';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  id: string;
};

export default function DisableSortItem({ id }: Props) {
  const { actions, orderBy } = useContext(Context);
  const { translate } = useLocales();

  const onClick = (event: any) => {
    event.stopPropagation();
    actions.onSort!(undefined);
  };

  return !orderBy || orderBy !== id ? (
    <SortItem direction={'desc'} id={id} />
  ) : (
    <MenuItem onClick={onClick} sx={{ px: 1 }}>
      <Typography variant="body2">{translate('Sin orden')}</Typography>
    </MenuItem>
  );
}
