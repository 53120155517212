import { Badge, Box, Link, ListItemText, Stack, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { NavItemProps } from '../type';

import BasicTooltip from 'src/components/BasicTooltip';
import useLocales from 'src/hooks/useLocales';
import { clientReset } from 'src/redux/slices/ClientSlice/clientSlice';
import { ConnectionType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { store } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { isExternalLink } from '..';
import Iconify from '../../Iconify';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';

// ----------------------------------------------------------------------

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemProps) {
  const { title, path, icon, info, children, isTranslate, highlightAdd } = item;

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const isMyConnections = title === 'navbar.my_connections';

  const { portfolio, client } = store.getState();
  const { connections, portfolios } = portfolio;
  const noPortfolio = Object.keys(portfolios).length === 0 && path === '/dashboard/wallets';
  const updateConnections = connections.filter(
    (i: ConnectionType) => i.pending || !i.displayed || i.errored
  );

  const handleOnClickMenuItem = () => {
    if (client && client.id !== '') {
      dispatch(clientReset());
    }
  };

  const renderPendingUpdateConnections = () => (
    <Tooltip title={'Tienes conexiones pendientes de actualizar o reconectar.'}>
      <Badge badgeContent={updateConnections.length} color="primary" sx={{ mr: 3 }} />
    </Tooltip>
  );

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        isCollapse={isCollapse}
        primary={isTranslate ? translate(title) : title}
      />
      {isMyConnections && updateConnections.length > 0 && renderPendingUpdateConnections()}
      {!isCollapse && (
        <>
          {info && info}
          {children && children.length > 0 && <ArrowIcon open={open} />}
          {children && children.length === 0 && highlightAdd && <AddIcon />}
        </>
      )}
    </>
  );

  if (children && children.length !== 0) {
    return (
      <ListItemStyle activeRoot={active} onClick={onOpen}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      //href={path}
      onClick={handleOnClickMenuItem}
      // rel="noopener"
      // target="_blank"
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      activeRoot={active}
      component={RouterLink}
      onClick={handleOnClickMenuItem}
      to={noPortfolio ? PATH_DASHBOARD.general.wallets.newWallet : path}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
  const { title, path, info, children, isTranslate, isConnected, isAdvisor } = item;
  const { translate } = useLocales();

  const renderAdvisorIcon = () => (
    <BasicTooltip title="Cartera gestionada por asesor">
      <Iconify
        color={active ? 'primary.main' : 'text.secondary'}
        height={14}
        icon={'mdi:user'}
        sx={{ ml: 1.4, mr: 0.2, mb: 0.3 }}
        width={14}
      />
    </BasicTooltip>
  );

  const renderContent = (
    <>
      <DotIcon active={active} />
      <Stack alignItems={'center'} flexDirection="row">
        <ListItemText disableTypography primary={isTranslate ? translate(title) : title} />
        {isConnected && (
          <Iconify
            color={'info.main'}
            height={12}
            icon={'octicon:verified-16'}
            sx={{ ml: 1.5, mr: 0.2 }}
            width={12}
          />
        )}
        {isAdvisor && renderAdvisorIcon()}
      </Stack>
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle activeSub={active} onClick={onOpen} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      //href={path}
      //rel="noopener"
      subItem
      //target="_blank"
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle activeSub={active} component={RouterLink} subItem to={path}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}

// ----------------------------------------------------------------------

export function AddIcon() {
  return (
    <Iconify icon={'ic:round-add'} sx={{ width: 14, height: 14, ml: 1, color: 'primary.main' }} />
  );
}
