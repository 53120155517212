// mui
import { Box } from '@mui/material';
// components
import BasicTooltip from 'src/components/BasicTooltip';
import Iconify from 'src/components/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import useGetInfoUser from '../services/useGetUserInfo';
// service
import useUpdateLatestPrices from '../services/useUpdateLatestPrices';

export default function ReloadPricesButton() {
  const { timeLeft, canUpdatePrices } = useGetInfoUser();
  const { updatePrices, loading } = useUpdateLatestPrices();

  const isSm = useResponsive('up', 'sm');
  const { translate } = useLocales();
  const tooltipPrices = canUpdatePrices
    ? translate('navbar.update_prices_available')
    : `${translate('navbar.update_prices_error')} ${timeLeft} ${translate('navbar.minutes')}`;

  function onClick() {
    if (!loading && canUpdatePrices) {
      updatePrices();
    }
  }

  return (
    <>
      <BasicTooltip title={tooltipPrices}>
        <IconButtonAnimate onClick={onClick} sx={{ p: { xs: 0, sm: 1 } }}>
          <Iconify icon={loading ? 'eos-icons:loading' : 'mdi:reload'} />
        </IconButtonAnimate>
      </BasicTooltip>

      {!isSm && (
        <BasicTooltip title={tooltipPrices}>
          <Box
            onClick={onClick}
            sx={{ textWrap: 'balance !important', whiteSpace: 'normal', ml: 2 }}
          >
            {tooltipPrices}
          </Box>
        </BasicTooltip>
      )}
    </>
  );
}
