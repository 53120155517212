import { LOGOS_URL, S3_URL, ENTITIES_URL, PROFILES_URL, FINLINK_ID, FINLINK_URL } from 'src/config';

const iFrameUrl = (theme: string, entity: string, locale: string = 'es_ES') =>
  `${FINLINK_URL}/link/connect-account?id=${FINLINK_ID}&themeType=${theme}&locale=${locale}&isFrame=true&entity=${entity}`;

export const widgetUrl = (
  theme: string,
  entity: string,
  userId: string,
  locale: string = 'es_ES',
  backgroundColor?: string,
  primaryColor?: string,
  contentBackgroundColor?: string
) =>
  `${iFrameUrl(
    theme,
    entity,
    locale
  )}&externalId=${userId}&backgroundColor=${backgroundColor}&primaryColor=${primaryColor}&contentBackgroundColor=${contentBackgroundColor}`;

export const updateWidgetUrl = (
  theme: string,
  entity: string,
  updateToken: string,
  locale: string = 'es_ES'
) => `${iFrameUrl(theme, entity, locale)}&updateToken=${updateToken}`;
export const restoreWidgetUrl = (
  theme: string,
  entity: string,
  restoreToken: string,
  locale: string = 'es_ES'
) => `${iFrameUrl(theme, entity, locale)}&restoreToken=${restoreToken}`;

export const entitiesUrl = (id: string) => `${S3_URL}/${ENTITIES_URL}/${id}.png`;
export const flagsUrl = (flag: string) => `${S3_URL}/country-flags/${flag}.svg`;
export const iconUrl = (icon: string) => `/icons/${icon}.svg`;
export const getlogoUrl = (logo: string) => `${S3_URL}/${LOGOS_URL}/${logo}`;
export const getProfilesUrl = (url: string) =>
  `${S3_URL}/${PROFILES_URL}/${url}?r=${Math.random() * 1000}`;

export const stockURL = (
  type: string | null,
  exchangeSymbol: string,
  tickerSymbol: string,
  name: string,
  brandName?: string
) => {
  const url = `${S3_URL}/${LOGOS_URL}/`;
  if (type === null) return url + 'cash.png';

  if (brandName !== undefined && brandName !== null && brandName !== '')
    return url + brandName + '.png';
  if (type === 'FUND' || type === 'ETF' || type === 'PP') {
    const fIcon = getFoundIcon(name) ?? '';
    return fIcon !== null && fIcon !== '' ? url + fIcon : '';
  }

  const exchangeUrl = exchangeSymbol !== null ? `-${exchangeSymbol}` : '';
  const bodyUrl = `${tickerSymbol}${exchangeUrl}`;
  return bodyUrl !== null && bodyUrl !== '' ? url + bodyUrl + `.png` : '';
};

export const getFoundIcon = (name: string) => {
  for (const wordObj of entitiesIconList) {
    if (name.toLocaleLowerCase().includes(wordObj.name)) {
      return wordObj.value + '.png';
    }
  }

  return null;
};

export const brandUrl = (brandName: string) => {
  const url = `${S3_URL}/${LOGOS_URL}/`;
  return url + brandName + '.png';
};

export const normalizeUrlName = (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-{2,}/g, '-');
};

const entitiesIconList = [
  { name: 'fidelity', value: 'fidelity' },
  { name: 'vanguard', value: 'vanguard' },
  { name: 'ishares', value: 'ishares' },
  { name: 'indexa', value: 'indexa' },
  { name: 'myinvestor', value: 'myinvestor' },
  { name: 'caixabank', value: 'caixabank' },
  { name: 'santander am', value: 'santanderam' },
  { name: 'BBVA', value: 'BBVA' },
  { name: 'ibercaja', value: 'ibercaja' },
  { name: 'kutxabank', value: 'kutxabank' },
  { name: 'sabadell', value: 'sabadell' },
  { name: 'bankinter', value: 'bankinter' },
  { name: 'mutuactivos', value: 'mutuactivos' },
  { name: 'bestinver', value: 'bestinver' },
  { name: 'renta 4', value: 'renta4' },
  { name: 'amundi', value: 'amundi' },
  { name: 'liberbank', value: 'liberbank' },
  { name: 'mapfre', value: 'mapfre' },
  { name: 'abante', value: 'abante' },
  { name: 'cobas', value: 'cobas' },
  { name: 'mediolanum', value: 'mediolanum' },
  { name: 'attitude', value: 'attitude' },
  { name: 'true value', value: 'truevalue' },
  { name: 'fundsmith', value: 'fundsmith' },
  { name: 'dnb', value: 'dnb' },
  { name: 'baillie gifford', value: 'bailliegifford' },
  { name: 'chronos global', value: 'chronosglobal' },
  { name: 'magallanes', value: 'magallanes' },
  { name: 'groupama', value: 'groupama' },
  { name: 'robeco', value: 'robeco' },
  { name: 'gvc gaesco', value: 'gvcgaesco' },
  { name: 'baelo', value: 'baelo' },
  { name: 'aberdeen', value: 'aberdeen' },
  { name: 'black rock', value: 'blackrock' },
  { name: 'dws', value: 'dws' },
  { name: 'bnp', value: 'bnp' },
  { name: 'j.p.morgan', value: 'jpmorgan' },
];

export const vumiIconURL = `${S3_URL}/${LOGOS_URL}/vumi_icon.png`;
export const vumiIconLightURL = `${S3_URL}/${LOGOS_URL}/vumi_icon_light.png`;
export const vumiIconDarkURL = `${S3_URL}/${LOGOS_URL}/vumi_icon_dark.png`;
