import { MenuItem, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
// components
import Iconify from 'src/components/Iconify';
// context
import Context from '../context';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  id: string;
  direction: 'asc' | 'desc';
};

export default function SortItem({ id, direction }: Props) {
  const { actions } = useContext(Context);
  const { translate } = useLocales();

  const arrowIcon = `solar:alt-arrow-${direction === 'asc' ? 'up' : 'down'}-bold-duotone`;

  const onClick = (event: any) => {
    event.stopPropagation();
    actions.onSort!(id, direction);
  };

  return (
    <MenuItem onClick={onClick} sx={{ px: 1 }}>
      <Stack alignItems={'center'} flexDirection={'row'} gap={2}>
        <Iconify icon={arrowIcon} />
        <Typography variant="subtitle2">
          {translate(`Orden ${direction === 'asc' ? 'ascendente' : 'descendente'}`)}
        </Typography>
      </Stack>
    </MenuItem>
  );
}
