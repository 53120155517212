import { Checkbox, TableCell } from '@mui/material';
import { useContext } from 'react';
// context
import Context from './context';

// ----------------------------------------------------------------------

export default function CheckboxSelector() {
  const { rowCount = 0, numSelected = 0, actions } = useContext(Context);
  return (
    <TableCell padding="checkbox">
      <Checkbox
        checked={rowCount > 0 && numSelected === rowCount}
        indeterminate={numSelected > 0 && numSelected < rowCount}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          actions.onSelectAllRows && actions.onSelectAllRows(event.target.checked)
        }
      />
    </TableCell>
  );
}
