import { Divider, Stack, SxProps } from '@mui/material';
import { useContext } from 'react';
// components
import MenuPopover from 'src/components/menu-popover';
import SortItem from './SortItem';
import DisableSortItem from './DisableSortItem';
import HideColumnItem from './HideColumnItem';
// context
import Context from '../context';

// ----------------------------------------------------------------------

const sx: SxProps = {
  mt: 1.5,
  ml: 0.75,
  backgroundImage:
    'url("https://assets.minimals.cc/public/assets/core/cyan-blur.png"), url("https://assets.minimals.cc/public/assets/core/red-blur.png")',
  '& .MuiMenuItem-root': {
    typography: 'body2',
    borderRadius: 0.75,
  },
};

type Props = {
  open: HTMLElement | null;
  onClose: VoidFunction;
  id: string;
};

export default function OptionsMenu({ open, onClose, id }: Props) {
  const { order, orderBy, actions } = useContext(Context);

  const handleClose = (event: any) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <MenuPopover anchorEl={open} onClose={handleClose} open={open} sx={sx}>
      <Stack>
        <SortItem direction={orderBy !== id ? 'asc' : order === 'asc' ? 'desc' : 'asc'} id={id} />
        <DisableSortItem id={id} />
        {actions.onHideColumn && (
          <>
            <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
            <HideColumnItem id={id} />
          </>
        )}
      </Stack>
    </MenuPopover>
  );
}
