import { MenuItem, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
// components
import Iconify from 'src/components/Iconify';
// context
import Context from '../context';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  id: string;
};

export default function HideColumnItem({ id }: Props) {
  const { actions } = useContext(Context);
  const { translate } = useLocales();

  const onClick = (event: any) => {
    event.stopPropagation();
    actions.onHideColumn && actions.onHideColumn(id);
  };

  return (
    <MenuItem onClick={onClick} sx={{ px: 1 }}>
      <Stack alignItems={'center'} flexDirection={'row'} gap={2}>
        <Iconify icon={'humbleicons:eye-close'} />
        <Typography variant="subtitle2">{translate('Ocultar columna')}</Typography>
      </Stack>
    </MenuItem>
  );
}
