import { SxProps, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import BasicTooltip from '../../BasicTooltip';
import TableCustomLabel from './TableCustomLabel';
// context
import CheckboxSelector from './CheckboxSelector';
import Context, { ContextType } from './context';

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string | undefined, newOrder?: 'asc' | 'desc') => void;
  onSelectAllRows?: (checked: boolean) => void;
  onHideColumn?: (id: string) => void;
  sx?: SxProps<Theme>;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  onHideColumn,
  sx,
}: Props) {
  const context: ContextType = {
    order,
    orderBy,
    headLabel,
    rowCount,
    numSelected,
    actions: { onSort, onSelectAllRows, onHideColumn },
  };

  return (
    <Context.Provider value={context}>
      <TableHead sx={sx}>
        <TableRow>
          {onSelectAllRows && <CheckboxSelector />}

          {headLabel.map((headCell) => (
            <TableCell
              align={headCell.align || 'left'}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ width: headCell.width, minWidth: headCell.minWidth, whiteSpace: 'nowrap' }}
            >
              <BasicTooltip title={headCell.tooltip ?? ''}>
                {onSort && !headCell.disabled ? (
                  <TableCustomLabel headCell={headCell} />
                ) : (
                  <TableSortLabel hideSortIcon sx={{ cursor: 'default' }}>
                    {headCell.label}
                  </TableSortLabel>
                )}
              </BasicTooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Context.Provider>
  );
}
