import { FinlinkEntity } from 'src/services/flanks/NetTypes';
import { TimeSeries } from 'src/redux/slices/MarketsSlice/marketsSlice.d';
import { UpcomingEvent } from 'src/services/calendar/NetTypes';

export type Transaction = {
  amount: number;
  automatic?: boolean;
  baseCurrencyCode: string;
  confirmed?: boolean;
  currencyCode: string;
  exchange: string;
  exchangeRate: number;
  fee: number;
  id: string;
  isin: string;
  localCurrencyCode: string;
  note: string;
  portfolio: string;
  portfolio?: string;
  security: string;
  securityType: string;
  shares: number;
  tickerSymbol: string;
  transactionDate: string;
  type: string;
  brandCompleteName?: string;
  alternativeInvestment?: string;
  retention?: number;
};

export type Exchange = {
  id: string;
  name: string;
  symbol: string;
  country: string;
  currencyCode: string;
  timezone: string;
};

export type Security = {
  id: string;
  type: SecurityType;
  tickerSymbol: string;
  name: string;
  shares: number;
  localPrice: number;
  localCurrency: string;
  price: number;
  industry: IndustryType;
  bep: number;
  hasSplits: boolean;
  country: string;
  exchange: Exchange;
};

export type SecurityInfo = {
  id: string;
  name: string;
  symbol: string;
  numberActiveShares: number;
  currentPrice: number;
  totalValue: number;
  gainLossRealized: number;
  gainLossUnrealized: number;
  profitRateTotal: number;
  isin: string;
  dividendAmounts: number;
  dividendTaxes: number;
  ownerNumbers: number;
  prices: SecurityPricesMap;
  country: string;
  market: string;
  sector: string;
  industry: string;
  currency: string;
  bep: number;
  exchangeRate: number;
  delisted: boolean;
  lastDate: string;
  brandName?: string;
  historicalPositions: HistoricalPositions[];
  futureEvents: UpcomingEvent[];
  activePositions: Positions[];
  earliestDate: string;
  updatedAt?: string;
};

export type SecurityPricesMap = {
  [period: string]: SecurityPrices;
};

export type SecurityPrices = {
  priceList: TimeSeries[];
  performanceList: TimeSeries[];
  lastEvents: UpcomingEvent[];
};

export type SecurityEvent = {
  id: string;
  tickerSymbol: string;
  name: string;
  isin: string;
  exchange: string;
};

export type AmountData = {
  amount: number;
  percent: number;
  chart: number[];
};

export type ChartPerformance = {
  amount: number;
  percent: number;
  period: string;
  riskMetrics?: RiskMetrics;
  annualizedPerformance?: number;
  timeSeries: TimeSeries[];
  investedAmount: TimeSeries[];
  positionsPerformance: PositionPerformance[];
};

export type TimeSeriesMap = {
  [period: string]: TimeSeries[];
};

export type ChartPerformanceMap = {
  [period: string]: ChartPerformance;
};

export type GainsPerPeriodMap = {
  [period: string]: GainsPerPeriod;
};

export type GainsPerPeriod = {
  axis: string[];
  gains: number[];
  invested: number[];
  totalValue: number[];
};

export type Portfolio = {
  absoluteValue: ChartPerformanceMap;
  accumulatedPerformances: AccumulatedPerformances;
  annualizedPerformances: PeriodValueMap;
  baseCurrency: string;
  connectionDetails?: ConnectionDetails;
  currencyPairPerformances: CurrencyPairTodaysPerformance[];
  dividends: IncomePortfolio;
  bonds: IncomePortfolio;
  empty: boolean;
  entity?: PortfolioEntity;
  firstTransactionDate: string;
  gainsPerPeriod: GainsPerPeriodMap;
  historicalPositions: HistoricalPositions[];
  id: string;
  importError?: boolean;
  incomplete?: boolean;
  lastUpdatedAt: number;
  latestOperation: string;
  liquidityConsidered?: boolean;
  monthlyPerformance: MonthlyPerformance;
  name: string;
  operations: Operations;
  owners: string[];
  performance: ChartPerformanceMap;
  performanceMeta: PerformanceMeta;
  performanceStatistics: PerformanceStatisticsMap;
  performanceType: PerformanceType;
  positions: Positions[];
  referenceIndices: ReferenceIndice[];
  riskFreeAssetBenchmark: RiskFreeAssetBenchmark;
  riskMetrics: RiskMetricsMap;
  role: PortfolioRole;
  settings: PortfolioSettings;
  stateDataTab: StateDataTab;
  status: string;
  summary?: PerformanceMeta;
  type?: string;
  todaysGainers: TodaysPositionPerformance[];
  todaysLosers: TodaysPositionPerformance[];
  todaysPositionPerformances: TodaysPositionPerformance[];
  transactions: Transaction[];
  transactionsCount: TransactionsCount;
  upcomingEvents: UpcomingEvent[];
  yearlyPerformance: YearlyPerformance;
};

export type RiskMetricsMap = { [period: string]: RiskMetrics };

export type AccumulatedPerformances = {
  accumulatedPerformanceByPeriod: PeriodValueMap;
  accumulatedValueByPeriod: PeriodValueMap;
};

export type YearlyPerformance = {
  performance: PeriodValueMap;
  value: PeriodValueMap;
};

export type PortfolioRole = 'ADVISOR' | 'OWNER' | 'CLIENT';

export type DataPerformanceTab = {
  startDate?: string;
  endDate?: string;
  performancePeriod: PeriodsType;
  isPerformanceType: boolean;
  selectedIndices: string[];
};

export type StateDataTab = {
  performance: DataPerformanceTab;
  tabRefresh: TabRefresh;
  isLoaded?: boolean;
};

export interface TabRefresh {
  refreshPerformance: boolean;
  refreshMovements: boolean;
  refreshPositions: boolean;
  refreshDividends: boolean;
}

export type PeriodValueMap = { [period: string]: number };

export type MonthlyPerformance = {
  performance: PeriodValueMap;
  value: PeriodValueMap;
};

export type PortfolioSummary = {
  currentValue: ValuePerformancePair;
  day: ValuePerformancePair;
  year: ValuePerformancePair;
  total: ValuePerformancePair;
};

export type ValuePerformancePair = {
  value: number;
  performance: number;
};

export type RiskMetrics = {
  maxDrawdown: MaxDrawDown;
  returnsMetrics: ReturnsMetrics;
  benchmarkMetrics: BenchmarkComparisonSummary;
};

export type RiskFreeAssetBenchmark = {
  id: string;
  name: string;
};

export type TodaysPositionPerformance = {
  currencyCode: string;
  currentValue: number;
  exchangeSymbol: string;
  gainLoss: number;
  localPrice: number;
  performance: number;
  positionName: string;
  shares: number;
  tickerSymbol: string;
  securityId: string;
  type: string;
  brandName?: string;
};

export type CurrencyPairTodaysPerformance = {
  currencyPair: string;
  performance: number;
  exchangeRate: number;
};

export type MaxDrawDown = {
  low: string;
  peak: string;
  maxDrawdown: number;
  recovery: number;
};

export type ReturnsMetrics = {
  deviation: DesviationTipe;
  expectedLosses: ExpectedLosses;
  meanReturn: number;
  negativePeriods: number;
  positivePeriods: number;
  performance: number;
  ratios: RatiosMetrics;
};

export type BenchmarkComparisonSummary = {
  correlation: number;
  alpha: number;
  beta: number;
  trackingError: number;
};

export type RatiosMetrics = {
  sharpe: number;
  sortino: number;
};

export type ExpectedLosses = {
  expectedShortfall: number;
  valueAtRisk: number;
};

export type DesviationTipe = {
  downside: number;
  standard: number;
  volatility: number;
};

export type ReferenceIndice = {
  annualPerformances: YearlyPerformance;
  accumulatedPerformances: AccumulatedPerformances;
  annualizedPerformances: PeriodValueMap;
  meta: ReferenceIndiceMeta;
  performanceTimeSeries: TimeSeriesMap;
  absoluteValueTimeSeries: TimeSeriesMap;
  components: CustomIndice[];
  riskMetrics: RiskMetricsMap;
};

export type ReferenceIndiceMeta = {
  id: string;
  currencyCode: string;
  name: string;
  symbol?: string;
};

export type CustomIndice = {
  referenceIndex: ReferenceIndice;
  weight: string;
};

export type SharedPortfolio = {
  id: string;
  name: string;
  isConnected: boolean;
  settings: PortfolioSettings;
  performanceMeta: PerformanceMeta;
  performanceType: PerformanceType;
  performance: ChartPerformanceMap;
  absoluteValue: ChartPerformanceMap;
  gainsPerPeriod: GainsPerPeriodMap;
  dividends: IncomePortfolio;
  bonds: IncomePortfolio;
  firstTransactionDate: string;
  positions: Positions[];
  transactions: Transaction[];
  transactionsCount: TransactionsCount;
  historicalPositions: HistoricalPositions[];
  performanceStatistics: PerformanceStatisticsMap;
  riskMetrics?: RiskMetrics;
  todaysPositionPerformances: TodaysPositionPerformance[];
  monthlyPerformance: MonthlyPerformance;
  todaysGainers: TodaysPositionPerformance[];
  todaysLosers: TodaysPositionPerformance[];
  currencyPairPerformances: CurrencyPairTodaysPerformance[];
  upcomingEvents: UpcomingEvent[];
};

export type PerformanceStatisticsMap = {
  [period: string]: PerformanceStatistics;
};

export type PerformanceStatistics = {
  deposits: number;
  removals: number;
  dividends: number;
  transactionFees: number;
  otherFees: number;
  interests: number;
  retentions: number;
  otherTaxes: number;
};

export type PortfolioSettings = {
  share: string;
  password?: string;
  showReturn: boolean;
  showMoney: boolean;
  showTransactions: boolean;
  showHoldings: boolean;
  showHistoricalPositions: boolean;
  showDividends: boolean;
  shareLink: string;
  activesNotFoundShowed?: boolean;
};

export type PortfolioSettingsMap = {
  [id: string]: PortfolioSettings;
};

export type ConnectionDetails = {
  cif: string;
  entityType: string;
  lastUpdate: string;
  connectionId?: string;
  connectionName?: string;
};

export type PortfolioEntity = {
  id: string;
  name: string;
};

export type IncomePortfolio = {
  incomeMap: IncomeMap;
  incomeByMonthAndYear: DividendMonthYear[];
  total: number;
  ytd: number;
};

export type DividendsByPeriod = {
  dividendPeriod: DividendTimeSeries[];
  dividendsByMonthAndYear: DividendMonthYear[];
};

export type IncomeMap = {
  [period: string]: TimeSeries[];
};

export type DividendMonthYear = {
  year: number;
  month: number;
  amount: number;
};

export type Positions = {
  assetAllocation: Record<string, Record<string, number>>;
  bep: number;
  brandName: string;
  country: string;
  currencyCode: string;
  currentValue: number;
  dividendAmount?: number;
  equityCountryAllocation: Record<string, number>;
  equityRegionTypeAllocation: Record<string, Record<string, number>>;
  exchangeSymbol: string;
  fixedIncomeCountryAllocation: Record<string, number>;
  fixedIncomeRegionTypeAllocation: Record<string, Record<string, number>>;
  group: string;
  industry: string;
  isin: string;
  localPrice: number;
  marketCap: string;
  performance: number;
  annualizedPerformance: number;
  portfolioId?: string;
  positionName: string;
  regionType: string;
  sector: string;
  sectorAllocation: Record<string, Record<string, number>>;
  securityId: string;
  shares: number;
  subIndustry: string;
  tickerSymbol: string;
  totalGainLoss: number;
  totalPerformance: number;
  type: string;
  unrealizedGainLoss: number;
  updateAt?: string;
  weight: number;
};

export type HistoricalPositions = {
  brandName?: string;
  buyDate: string;
  exchangeSymbol: string;
  finalValue: number;
  initialValue: number;
  isin: string;
  performance: number;
  unrealizedGainLoss: number;
  portfolioId?: string;
  positionName: string;
  securityId: string;
  sellDate: string;
  shares: number;
  tickerSymbol: string;
  totalGainLoss: number;
  totalPerformance: number;
  type: string;
};

export type PositionPerformance = {
  active: boolean;
  performance: number;
  positionGainLoss: number;
  dividends: number;
  fees: number;
  securityId: string;
  positionName: string;
  tickerSymbol: string;
  type: string;
  exchangeSymbol: string;
  isin: string;
};

export type Operations = {
  count: OperationsMap;
  vol: OperationsMap;
};

export type OperationsData = {
  buys: number[];
  sells: number[];
  transfersOut: number[];
  transfersIn: number[];
};

export type OperationsMap = {
  [year: string]: OperationsData;
};

export type PerformanceMeta = {
  day: ValuePerformanceType;
  year: ValuePerformanceType;
  total: ValuePerformanceType;
  lastFullYear: ValuePerformanceType;
  currentValue: ValuePerformanceType;
};

export type ValuePerformanceType = {
  value: number;
  performance: number;
};

export type PortfolioMap = {
  [portfolioId: string]: Portfolio;
};

export type ConnectionType = {
  id: string;
  name: string;
  entity: string;
  entityType?: string;
  pending: boolean;
  errored: boolean;
  blocked: boolean;
  lastUpdate: string;
  displayed: boolean;
  updating: boolean;
  owner: string;
  latestAvailabilityChange?: string;
  email?: string;
  needsReimport?: boolean;
  accounts: ConnectionAccount[];
  investmentsCash?: number;
  investments?: number;
  cashAccounts?: number;
  provider?: string;
  updateToken?: string;
  restoreToken?: string;
  hasCredentials?: boolean;
  twoFactorEnabled?: boolean;
};

export type ConnectionAccount = {
  id: string;
  name: string;
  type: string;
  active: boolean;
  importError: boolean;
  incomplete: boolean;
  owners: string[];
};

export type GetAccountsPayload = {
  id: string;
  accounts: ConnectionAccount[];
};

export type DeleteMovementPayload = {
  portfolioId: string;
  transactionId: string;
};

export type TransactionsCount = {
  total: number;
  buys: number;
  sells: number;
  deposits: number;
  removals: number;
  dividends: number;
  splits: number;
  fees: number;
  interest: number;
  custom: number;
  interestCharge: number;
  notConfirmed: number;
  transfersInCount: number;
  transfersOutCount: number;
  taxes: number;
  taxRefund: number;
  interest: number;
};

export type TransactionsPayload = {
  page: number;
  size: number;
  type: string;
  actionType?: string;
  reset: boolean;
  startDate?: string | null;
  endDate?: string | null;
  min?: string | null;
  max?: string | null;
  name?: string;
  assetType?: string;
};

export type ImportError = {
  security: string;
  isin: string;
  reason: string;
  solved?: boolean;
};

export type NetWorthType = {
  cash: number;
  cashAccounts: number;
  investments: number;
  performance: TimeSeriesMap;
  dailyGain: number;
};

export type NetworthPeriodPayload = {
  performance: TimeSeriesMap;
  dailyGain: number;
};

export type UpdateAllPayload = {
  portfolios: PortfolioMap;
  agregatedSummary: PortfolioSummary;
};

export type UpdatePerformanceTabPayload = {
  id: string;
  startDate?: string;
  endDate?: string;
  performancePeriod: PeriodsType;
  isPerformanceType: boolean;
  selectedIndices: string[];
};

export type UpdateTabRefreshPayload = {
  id: string;
  tabRefresh: TabRefresh;
};

export type PerformanceType = 'MWRR' | 'TWRR';

export type PeriodsType = '7D' | '1M' | '6M' | 'YTD' | '1Y' | '3Y' | '5Y' | 'ALL' | 'CUSTOM';
export type DividendPeriodsType = 'YTD' | '1Y' | '3Y' | '5Y' | 'ALL';

export const PERIODS: PeriodsType[] = ['7D', '1M', '6M', 'YTD', '1Y', '3Y', '5Y', 'ALL'];
export const SHORT_PERIODS: PeriodsType[] = ['7D', '1M', '6M', 'YTD', '1Y', 'ALL'];
export const RISK_PERIODS: PeriodsType[] = ['1M', '6M', 'YTD', '1Y', '3Y', '5Y', 'ALL', 'CUSTOM'];
export const PERIODS_CUSTOM: PeriodsType[] = [
  '7D',
  '1M',
  '6M',
  'YTD',
  '1Y',
  '3Y',
  '5Y',
  'ALL',
  'CUSTOM',
];
export const DIV_PERIODS: DividendPeriodsType[] = ['YTD', '1Y', '3Y', '5Y', 'ALL'];

export type ESecurityType =
  | 'ALL'
  | 'STOCK'
  | 'REIT'
  | 'FUND'
  | 'ETF'
  | 'BOND'
  | 'SCRIP_DIVIDEND'
  | 'CALL'
  | 'PP'
  | 'PUT';

export const datePeriodsFormat: Map<PeriodsType, string> = new Map([
  ['7D', 'DD dd MMM'],
  ['1M', 'DD MMM YYYY'],
  ['6M', 'DD MMM YYYY'],
  ['1Y', 'DD MMM YYYY'],
  ['YTD', 'DD MMM YYYY'],
  ['3Y', 'DD MMM YYYY'],
  ['5Y', 'DD MMM YYYY'],
  ['ALL', 'DD MMM YYYY'],
  ['CUSTOM', 'DD MMM YYYY'],
]);

export const dateXAxisPeriodsFormat: Map<PeriodsType, string> = new Map([
  ['7D', 'DD dd'],
  ['1M', 'DD MMM'],
  ['6M', 'DD MMM YY'],
  ['1Y', 'MMM YY'],
  ['YTD', 'DD MMM YY'],
  ['3Y', 'MMM YY'],
  ['5Y', 'MMM YY'],
  ['ALL', 'MMM YY'],
  ['CUSTOM', 'DD MMM YY'],
]);

export const dateXAxisPeriodsFormatMobile: Map<PeriodsType, string> = new Map([
  ['7D', 'DD dd'],
  ['1M', 'DD MMM'],
  ['6M', 'MMM YY'],
  ['1Y', 'MMM YY'],
  ['YTD', 'MMM YY'],
  ['3Y', 'MMM YY'],
  ['5Y', 'MMM YY'],
  ['ALL', 'MMM YY'],
  ['CUSTOM', 'MMM YY'],
]);

export type TransactionPayload = {
  id: string;
  transactions: Transaction[];
};

export type TransactionCountPayload = {
  id: string;
  transactionsCount: TransactionsCount;
};

export type OperationsPayload = {
  id: string;
  operations: Operations;
};

export type UpdatePortfolioPerformancePayload = {
  id: string;
  performanceMeta: PerformanceMeta;
  performance: ChartPerformanceMap;
  absoluteValue: ChartPerformanceMap;
  performanceStatistics: PerformanceStatisticsMap;
  performanceType: PerformanceType;
  monthlyPerformance: MonthlyPerformance;
  referenceIndices: ReferenceIndice[];
  yearlyPerformance: YearlyPerformance;
  accumulatedPerformances: AccumulatedPerformances;
  annualizedPerformances: PeriodValueMap;
};

export type UpdatePortfolioRiskPayload = {
  id: string;
  riskMetrics: RiskMetricsMap;
  referenceIndices: ReferenceIndice[];
};

export type UpdatePortfolioEntity = {
  id: string;
  entity: PortfolioEntity;
};

export const defaultFlanksEntity: FinlinkEntity = {
  id: '',
  name: '',
  entityType: '',
};

const defaultValuePerformancePair: ValuePerformancePair = { value: 0, performance: 0 };

export const defaultNetWorth: NetWorthType = {
  cash: 0,
  cashAccounts: 0,
  investments: 0,
  dailyGain: 0,
  performance: { '7D': [] },
};
export const defaultAgregated: PortfolioSummary = {
  currentValue: defaultValuePerformancePair,
  day: defaultValuePerformancePair,
  year: defaultValuePerformancePair,
  total: defaultValuePerformancePair,
};

export const defaultPerformance: DataPerformanceTab = {
  startDate: undefined,
  endDate: undefined,
  performancePeriod: '7D',
  isPerformanceType: true,
  selectedIndices: ['portfolio'],
};
