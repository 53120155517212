import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  Stack,
  SxProps,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { debounce } from 'lodash';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import SimpleLabel from 'src/components/SimpleLabel';
import { RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import { ESecurityType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { getNetworkClient } from 'src/services/config';
import { CountBySecurityType } from 'src/services/portfolio/NetTypes';
import {
  getAllSecuritiesByTermCountRequest,
  getSecuritiesByTermRequest,
} from 'src/services/securities';
import { ActionTypeDict } from 'src/utils/getColorName';

const debouncedOnSearchMs = 400;

// ----------------------------------------------------------------------
const TYPES: ESecurityType[] = [
  'ALL',
  'STOCK',
  'ETF',
  'FUND',
  'REIT',
  'BOND',
  //'SCRIP_DIVIDEND',
  //'CALL',
  //'PUT',
  'PP',
];

export type SecurityType = {
  name: string;
  tickerSymbol: string;
  id: string;
  currencyCode: string;
  exchange: string;
  earliestAvailableData?: Date;
  hasSplits: boolean;
  tracked: boolean;
  incomplete?: boolean;
  lastTradedDay?: string;
  isin?: string;
  type: string;
};

type Props = {
  name: string;
  security: SecurityType;
  onSelectSecurity: (security: SecurityType) => void;
  sx?: SxProps;
};

export default function SecuritiesSearch({ onSelectSecurity, name, security, sx }: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SecurityType[]>([]);
  const [securitiesCount, setSecuritiesCount] = useState<CountBySecurityType>();

  const [filterSelected, setFilterSelected] = useState<string>('ALL');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const { translate } = useLocales();
  const theme = useTheme();
  const isMidSize = useResponsive('down', 'md');
  const isDownLg = useResponsive('down', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const defaultSecurity: any = { id: 'more', name: '', tickerSymbol: '', isin: '' };

  const onSearchService = (term: string, type: string, page: number) => {
    setSearchQuery(term);
    if (term) {
      getNetworkClient().executeRequest(
        getSecuritiesByTermRequest(
          term,
          type === 'ALL' ? '' : type,
          page,
          ({ data }) => {
            const newSecurities: SecurityType[] =
              page === 0
                ? data.securities
                : [...searchResults.filter((i) => i.id !== 'more'), ...data.securities];
            setSearchResults(newSecurities);
            if (term !== searchQuery) {
              getSecuritiesCount(term, newSecurities);
            } else {
              checkAddMoreButton(newSecurities, securitiesCount);
              setIsLoading(false);
            }
            setIsError(false);
          },
          () => {
            setSecuritiesCount(undefined);
            setSearchResults([]);
            setIsLoading(false);
            setIsError(true);
          }
        )
      );
    } else {
      setSearchResults([]);
      setIsLoading(false);
    }
  };

  const checkAddMoreButton = (securities: SecurityType[], count?: CountBySecurityType) => {
    if (
      count &&
      (filterSelected === 'ALL'
        ? securities.length < Object.values(count).reduce((sum, i) => sum + i, 0)
        : securities.length < count[filterSelected])
    )
      setSearchResults([...securities, defaultSecurity]);
  };

  const onSearch = useCallback((term: string, type: string, page: number) => {
    onSearchService(term, type, page);
  }, []);

  const getSecuritiesCount = (term: string, securities: SecurityType[]) => {
    getNetworkClient().executeRequest(
      getAllSecuritiesByTermCountRequest(
        term,
        (data: CountBySecurityType) => {
          setSecuritiesCount(data);
          checkAddMoreButton(securities, data);
          setIsLoading(false);
          setIsError(false);
        },
        () => {
          setIsLoading(false);
          setIsError(true);
        }
      )
    );
  };

  const getMoreSecurities = () => {
    setIsLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    onSearchService(searchQuery, filterSelected, newPage);
  };

  const debouncedOnSearch = useMemo(
    () =>
      debounce(
        (term: string, filterSelected: string, page: number) =>
          onSearch?.(term, filterSelected, page),
        debouncedOnSearchMs
      ),
    [onSearch]
  );

  const handleOnSelectFilter = (type: string) => {
    setIsLoading(true);
    setSearchResults([]);
    setFilterSelected(type);
  };

  useEffect(() => {
    setPage(0);
    debouncedOnSearch(searchQuery, filterSelected, 0);
  }, [debouncedOnSearch, searchQuery, filterSelected]);

  const renderHeaderItem = (type: string, isEmpty?: boolean) => {
    const isSelected = filterSelected === type;
    return (
      <Button
        color="inherit"
        onClick={() => handleOnSelectFilter(type)}
        size="small"
        sx={{
          mr: 1,
          display: 'inline-block',
          paddingX: 1.5,
          minWidth: 0,
          mb: isEmpty ? 0 : 1,
          backgroundColor: alpha(theme.palette.primary.main, isSelected ? 0.16 : 0),
        }}
      >
        <Typography
          color={isSelected ? 'primary.light' : 'text.primary'}
          fontSize={12}
          variant="subtitle1"
        >
          {`${type !== 'ALL' ? (securitiesCount && securitiesCount[type]) ?? '' : ''}` +
            (type === 'ALL' ? '' : '\xa0\xa0') +
            translate(`positions.${type}`)}
        </Typography>
      </Button>
    );
  };

  const renderHeaderList = (isEmpty?: boolean) => (
    <Grid
      container
      sx={{
        position: isEmpty ? 'relative' : 'absolute',
        backgroundColor: 'background.paper',
        pt: isEmpty ? 0 : 1,
      }}
    >
      {searchQuery !== '' &&
        TYPES.filter(
          (k) => k === 'ALL' || (securitiesCount && securitiesCount[k] && securitiesCount[k] !== 0)
        ).map((i, index) => (
          <Grid item key={index}>
            {renderHeaderItem(i, isEmpty)}
          </Grid>
        ))}
    </Grid>
  );

  return (
    <Autocomplete
      autoHighlight
      filterOptions={(options) => options}
      fullWidth
      getOptionLabel={({ exchange, tickerSymbol, name }: SecurityType) =>
        exchange && tickerSymbol ? `${exchange}:${tickerSymbol}` : tickerSymbol || name
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      noOptionsText={
        <>
          {renderHeaderList(true)}
          <SearchNotFound searchQuery={searchQuery} sx={{ mt: 2 }} />
        </>
      }
      onChange={(_e, v) => {
        if (v === null) {
          onSelectSecurity({
            name: '',
            tickerSymbol: '',
            id: '',
            currencyCode: '',
            exchange: '',
            hasSplits: false,
            isin: '',
            tracked: false,
            type: '',
          });
          return;
        }
        onSelectSecurity({
          id: v.id,
          name: v.name,
          currencyCode: v.currencyCode,
          tickerSymbol: v.tickerSymbol,
          exchange: v.exchange,
          isin: v.isin,
          earliestAvailableData: v.earliestAvailableData,
          lastTradedDay: v.lastTradedDay,
          hasSplits: v.hasSplits,
          tracked: v.tracked,
          type: v.type,
        });
      }}
      onClose={() => setFilterSelected('ALL')}
      onInputChange={(_, value) => {
        setIsLoading(true);
        setSearchResults([]);
        setSearchQuery(value);
      }}
      options={searchResults}
      popupIcon={null}
      renderInput={(params) => (
        <RHFTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
          label={translate('securities_search.placeholder')}
          name={name}
          placeholder={'Buscar...'}
        />
      )}
      renderOption={(props, security, { inputValue }) => {
        const { id, name, tickerSymbol, exchange, currencyCode, isin, type } = security;
        const nameMatches = match(name, inputValue, { insideWords: true });
        const nameParts = parse(name, nameMatches);
        const symbolMatches = match(tickerSymbol ?? '', inputValue);
        const symbolParts = parse(tickerSymbol ?? '', symbolMatches);
        const isinMatches = match(isin ?? '', inputValue);
        const isinParts = parse(isin ?? '', isinMatches);
        const isFirst = searchResults[0].id === id;
        const filters = TYPES.filter(
          (k) => k === 'ALL' || (securitiesCount && securitiesCount[k] && securitiesCount[k] !== 0)
        );
        const filtersTotal = filters.reduce(
          (sum, i) => sum + (i !== 'ALL' && securitiesCount ? securitiesCount[i] : 0),
          0
        );

        const marginTop = isMidSize
          ? filters.length > 6
            ? 120
            : filters.length > 3
            ? 80
            : 40
          : isDownLg
          ? filters.length > 6 && filtersTotal > 17000
            ? 80
            : 45
          : 45;

        return (
          <>
            {isFirst && renderHeaderList()}
            {id !== 'more' ? (
              <li
                {...props}
                key={id}
                style={{
                  minWidth: '100%',
                  marginTop: isFirst ? marginTop : 0,
                }}
              >
                <Link minWidth="100%" underline="none">
                  <Box minWidth="100%" sx={{ flexGrow: 1 }}>
                    <Stack
                      alignSelf="stretch"
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      minWidth="100%"
                    >
                      <Stack alignSelf="center" flexDirection={'row'}>
                        {nameParts.map((part, index) => (
                          <Typography
                            color={part.highlight ? 'primary' : 'textPrimary'}
                            component="span"
                            key={index}
                            sx={{ whiteSpace: 'pre' }}
                            variant="subtitle2"
                          >
                            {part.text}
                          </Typography>
                        ))}
                      </Stack>
                      {!isMobile && type && (
                        <SimpleLabel
                          color={ActionTypeDict[type]?.color ?? 'primary'}
                          component="span"
                          fontSize={12}
                        >
                          {translate(`positions.${type}`)}
                        </SimpleLabel>
                      )}
                    </Stack>

                    <Stack
                      alignSelf="stretch"
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      minWidth="100%"
                      sx={{ mt: 1 }}
                    >
                      <Box>
                        {exchange! && (
                          <Typography
                            component="span"
                            sx={{
                              color: 'text.secondary',
                            }}
                            variant="body2"
                          >
                            {exchange}:
                          </Typography>
                        )}
                        {symbolParts.map((part, index) => (
                          <Typography
                            color={part.highlight ? 'primary' : 'text.secondary'}
                            component="span"
                            key={index}
                            variant="body2"
                          >
                            {part.text.replace('_manual', '')}
                          </Typography>
                        ))}
                        {isin! && (exchange! || tickerSymbol!) && (
                          <Typography
                            component="span"
                            sx={{
                              color: 'text.secondary',
                              mx: 0.5,
                            }}
                            variant="body2"
                          >
                            {' - '}
                          </Typography>
                        )}
                        {isin! &&
                          isinParts.map((part, index) => (
                            <Typography
                              color={part.highlight ? 'primary' : 'text.secondary'}
                              component="span"
                              key={index}
                              variant="body2"
                            >
                              {part.text}
                            </Typography>
                          ))}
                      </Box>
                      <Typography
                        sx={{
                          color: 'text.secondary',
                        }}
                        variant="body2"
                      >
                        {currencyCode}
                      </Typography>
                    </Stack>
                  </Box>
                </Link>
              </li>
            ) : (
              <LoadingButton
                loading={isLoading}
                onClick={() => getMoreSecurities()}
                sx={{ mb: 1, width: '100%' }}
              >
                <Typography
                  alignSelf={'center'}
                  color={'primary'}
                  component="span"
                  textAlign={'center'}
                  variant="subtitle2"
                >
                  {'Ver más'}
                </Typography>
              </LoadingButton>
            )}
          </>
        );
      }}
      size="medium"
      sx={sx}
      value={security}
    />
  );
}
