import { Box, TableSortLabel } from '@mui/material';
import { useContext, useState } from 'react';
// components
import IconComponent from './IconComponent';
// context
import Context from './context';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  headCell: any;
};

export default function TableCustomLabel({ headCell }: Props) {
  const [hideSortIcon, setHideSortIcon] = useState(true);

  const { order, orderBy, actions } = useContext(Context);

  const direction = orderBy === headCell.id ? order : 'asc';

  const onSort = (event: any) => {
    actions.onSort && actions.onSort(headCell.id);
    event.stopPropagation();
  };

  return (
    <TableSortLabel
      IconComponent={() => (
        <IconComponent direction={direction} hideOptions={hideSortIcon} id={headCell.id} />
      )}
      active={orderBy === headCell.id}
      direction={orderBy === headCell.id ? order : 'asc'}
      hideSortIcon={hideSortIcon}
      onClick={onSort}
      onMouseEnter={() => setHideSortIcon(false)}
      onMouseLeave={() => setHideSortIcon(true)}
    >
      {headCell.label}

      {orderBy === headCell.id ? (
        <Box sx={{ ...visuallyHidden }}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );
}
