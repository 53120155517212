import { useState, Children, cloneElement } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
// components
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
import MoreButtonItems from './MoreButtonPopover/MoreButtonItems';
import { Stack } from '@mui/material';
import ReloadPricesButton from './ReloadPricesButton';
import HideAccountButton from './HideAccountButton';

export default function TopBarButtonsMenu({ children }: any) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const onClose = () => setOpen(null);

  const props = {
    menu: {
      anchorEl: open,
      onClose,
      open: Boolean(open),
    },
  };

  return (
    <>
      {/* Menu trigger */}
      <IconButtonAnimate onClick={(e: React.MouseEvent<HTMLElement>) => setOpen(e.currentTarget)}>
        <Iconify icon="ep:menu" />
      </IconButtonAnimate>

      <MenuPopover {...props.menu}>
        <MenuItem>
          <ReloadPricesButton />
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
        <MenuItem>
          <HideAccountButton />
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed', my: 1 }} />
        <MoreButtonItems onClick={onClose} />
      </MenuPopover>
    </>
  );
}
