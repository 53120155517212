import { TFunction } from 'i18next';
import { LabelColor, LabelColorVariant } from 'src/components/SimpleLabel';
import { TransactionsCount } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { getCurrencyFromBase } from 'src/utils/currency';
// utils
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import mockData from 'src/pages/dashboard/AddAssets/Alternative/Type/mockData';
import { ColumnTable } from 'src/components/table/columns/ColumnsFilter';

export const getCountByFilter = (filter: string, transactionsCount: TransactionsCount) => {
  switch (filter) {
    case 'ALL':
      return transactionsCount.total;
    case 'BUY':
      return transactionsCount.buys;
    case 'SELL':
      return transactionsCount.sells;
    case 'DEPOSIT':
      return transactionsCount.deposits;
    case 'REMOVAL':
      return transactionsCount.removals;
    case 'DIVIDENDS':
      return transactionsCount.dividends;
    case 'FEES':
      return transactionsCount.fees;
    case 'INTEREST_CHARGE':
      return transactionsCount.interestCharge;
    default:
      return transactionsCount.total;
  }
};

export const TABLE_HEAD = (translate: any, baseCurrency: string): any => [
  { id: 'type', label: translate('movements.type'), align: 'left' },
  { id: 'product', label: translate('movements.product'), align: 'center' },
  { id: 'date', label: translate('movements.date'), align: 'center' },
  { id: 'market', label: translate('movements.market'), align: 'center' },
  { id: 'currency', label: translate('movements.currency'), align: 'center' },
  { id: 'quantity', label: 'Nº', align: 'center' },
  { id: 'price', label: translate('movements.price'), align: 'center' },
  { id: 'localValue', label: translate('movements.local_value'), align: 'center' },
  {
    id: 'currencyValue',
    label: translate('movements.value') + ` (${getCurrencyFromBase(baseCurrency)})`,
    align: 'center',
  },
  { id: 'exchangeRate', label: translate('movements.exchangeRate'), align: 'center' },
  {
    id: 'fee',
    label: translate('movements.fee') + ` (${getCurrencyFromBase(baseCurrency)})`,
    align: 'center',
  },
  {
    id: 'taxes',
    label: translate('movements.taxes') + ` (${getCurrencyFromBase(baseCurrency)})`,
    align: 'center',
  },
  { id: 'total', label: translate('movements.total'), align: 'center' },
  { id: '', label: '', align: 'center' },
];

export const headColumns: ColumnTable[] = [
  { id: 'type', title: 'movements.type', isSelected: true },
  { id: 'product', title: 'movements.product', isSelected: true },
  { id: 'date', title: 'movements.date', isSelected: true },
  { id: 'market', title: 'movements.market', isSelected: true },
  { id: 'currency', title: 'movements.currency', isSelected: true },
  { id: 'quantity', title: 'Nº', isSelected: true },
  { id: 'price', title: 'movements.price', isSelected: true },
  { id: 'localValue', title: 'movements.local_value', isSelected: true },
  {
    id: 'currencyValue',
    title: 'movements.value',
    isSelected: true,
  },
  { id: 'exchangeRate', title: 'movements.exchangeRate', isSelected: true },
  {
    id: 'fee',
    title: 'movements.fee',
    isSelected: true,
  },
  {
    id: 'taxes',
    title: 'movements.taxes',
    isSelected: true,
  },
  { id: 'total', title: 'movements.total', isSelected: true },
];

export const headAltColumns: ColumnTable[] = [
  { id: 'type', title: 'movements.type', isSelected: true },
  { id: 'product', title: 'movements.product', isSelected: true },
  { id: 'isin', title: 'ISIN', isSelected: true },
  { id: 'date', title: 'movements.date', isSelected: true },
  { id: 'currency', title: 'movements.currency', isSelected: true },
  { id: 'quantity', title: 'Nº', isSelected: true },
  { id: 'price', title: 'movements.price', isSelected: true },
  { id: 'localValue', title: 'movements.local_value', isSelected: true },
  {
    id: 'currencyValue',
    title: 'movements.value',
    isSelected: true,
  },
  { id: 'exchangeRate', title: 'movements.exchangeRate', isSelected: true },
  {
    id: 'fee',
    title: 'movements.fee',
    isSelected: true,
  },
  {
    id: 'retention',
    title: 'movements.retention',
    isSelected: true,
  },
  { id: 'total', title: 'movements.total', isSelected: true },
];

export const SERVICE_OPTIONS = [
  'ALL',
  'BUY',
  'SELL',
  'DIVIDENDS',
  'DEPOSIT',
  'REMOVAL',
  'FEES',
  'SPLIT',
  'INTEREST_CHARGE',
];

export const TransactionsTypesDict: any = {
  ALL: {
    label: 'movements.all',
    color: 'info',
    variant: 'main',
    type: 'total',
  },
  BUY: {
    label: 'movements.buys',
    color: 'success',
    variant: 'main',
    type: 'buys',
  },
  SELL: {
    label: 'movements.sells',
    color: 'error',
    variant: 'main',
    type: 'sells',
  },
  COUPON: {
    label: 'wallets.COUPON',
    color: 'warning',
    variant: 'light',
    type: 'coupon',
  },
  OFF_MARKET_BUY: {
    label: 'movements.off_market_buy',
    color: 'success',
    variant: 'main',
    type: 'buys',
  },
  OFF_MARKET_SELL: {
    label: 'movements.off_market_sell',
    color: 'error',
    variant: 'main',
    type: 'sells',
  },
  DIVIDENDS: {
    label: 'movements.dividends',
    color: 'warning',
    variant: 'light',
    type: 'dividends',
  },
  CAPITAL_GAINS: {
    label: 'movements.capital_gains',
    color: 'warning',
    variant: 'light',
    type: 'dividends',
  },
  SHARE_ISSUE_PREMIUM: {
    label: 'Prima de emisión',
    color: 'warning',
    variant: 'light',
    type: 'dividends',
  },
  RETURN_ON_CAPITAL: {
    label: 'movements.return_on_capital',
    color: 'warning',
    variant: 'light',
    type: 'dividends',
  },
  SPLIT: {
    label: 'movements.splits',
    color: 'secondary',
    variant: 'light',
    type: 'splits',
  },
  NOT_CONFIRMED: {
    label: 'movements.not_confirmed',
    color: 'warning',
    variant: 'main',
    type: 'not_confirmed',
  },
  DEPOSIT: {
    label: 'movements.deposits',
    color: 'primary',
    variant: 'main',
    type: 'deposits',
  },
  REMOVAL: {
    label: 'movements.removals',
    color: 'error',
    variant: 'light',
    type: 'removals',
  },
  FEES: {
    label: 'movements.fees',
    color: 'default',
    variant: 'main',
    type: 'fees',
  },
  FEES_REFUND: {
    label: 'wallets.fees_refund',
    color: 'default',
    variant: 'main',
    type: 'fees_refund',
  },
  INTEREST_CHARGE: {
    label: 'movements.interest_charge',
    color: 'info',
    variant: 'light',
  },
  TRANSFER_IN: {
    label: 'movements.transfer_in',
    color: 'success',
    variant: 'light',
  },
  TRANSFER_OUT: {
    label: 'movements.transfer_out',
    color: 'error',
    variant: 'light',
  },
  TAXES: {
    label: 'movements.taxes',
    color: 'default',
    variant: 'main',
    type: 'taxes',
  },
  TAX_REFUND: {
    label: 'movements.tax_refund',
    color: 'default',
    variant: 'main',
    type: 'taxes',
  },
  INTEREST: {
    label: 'movements.interest',
    color: 'default',
    variant: 'main',
    type: 'taxes',
  },
  ISIN_CHANGE: {
    label: 'movements.isin_change',
    color: 'default',
    variant: 'main',
  },
  PRODUCT_CHANGE: {
    label: 'movements.product_change',
    color: 'default',
    variant: 'main',
  },
  LIQUIDITY_TRANSFER_IN: {
    label: 'movements.liquidity_transfer_in',
    color: 'default',
    variant: 'main',
  },
  LIQUIDITY_TRANSFER_OUT: {
    label: 'movements.liquidity_transfer_out',
    color: 'default',
    variant: 'main',
  },
  GIFT: {
    label: 'movements.gift',
    color: 'default',
    variant: 'main',
  },
  RIGHTS_ISSUE: {
    label: 'movements.rights_issue',
    color: 'default',
    variant: 'main',
  },
  RIGHTS_EXCLUSION: {
    label: 'movements.rights_exclusion',
    color: 'default',
    variant: 'main',
  },
  RIGHTS_CONVERSION: {
    label: 'movements.rights_conversion',
    color: 'default',
    variant: 'main',
  },
  SPINOFF: {
    label: 'Spin-off',
    color: 'default',
    variant: 'main',
  },
  DIVIDEND_RETENTION: {
    label: 'movements.dividend_retention',
    color: 'default',
    variant: 'main',
  },
  DIVIDEND_RETENTION_REFUND: {
    label: 'movements.dividend_retention_refund',
    color: 'default',
    variant: 'main',
  },
  CAPITAL_GAINS_RETENTION: {
    label: 'movements.capital_gains_retention',
    color: 'default',
    variant: 'main',
  },
  CAPITAL_GAINS_RETENTION_REFUND: {
    label: 'movements.capital_gains_retention_refund',
    color: 'default',
    variant: 'main',
  },
  DELISTING: {
    label: 'Deslistada',
    color: 'error',
    variant: 'main',
    type: 'sells',
  },
  UNKNOWN_TRANSACTION: {
    label: 'Transaccion desconocida',
    color: 'default',
    variant: 'main',
  },
  UNKNOWN: {
    label: 'Transaccion desconocida',
    color: 'default',
    variant: 'main',
  },
  SCRIP_DIVIDEND: {
    label: 'Dividendo en acciones',
    color: 'success',
    variant: 'main',
    type: 'buys',
  },
};

export type TabMovementsType = {
  value: string;
  label: string;
  color: LabelColor;
  variant: LabelColorVariant;
  count: number;
};

function getCount(types: string[], transactionCount?: TransactionsCount) {
  if (!transactionCount || !types) return 0;
  return reduce([...types], (sum, n) => sum + get(transactionCount, n, 0), 0);
}

export const getTabs = (
  translate: TFunction,
  transactionCount?: TransactionsCount
): TabMovementsType[] => [
  {
    value: 'ALL',
    label: translate('movements.all'),
    color: 'info',
    variant: 'main',
    count: transactionCount?.total ?? 0,
  },
  {
    value: 'BUY,OFF_MARKET_BUY, BUY,SCRIP_DIVIDEND',
    label: translate('movements.buys'),
    color: 'success',
    variant: 'main',
    count: transactionCount?.buys ?? 0,
  },
  {
    value: 'SELL,OFF_MARKET_SELL, SELL,DELISTING',
    label: translate('movements.sells'),
    color: 'error',
    variant: 'main',
    count: transactionCount?.sells ?? 0,
  },
  {
    value: 'DIVIDENDS,CAPITAL_GAINS,SHARE_ISSUE_PREMIUM,RETURN_ON_CAPITAL',
    label: translate('wallets.income'),
    color: 'warning',
    variant: 'light',
    count: getCount(['dividends', 'returnOnCapital', 'capitalGains'], transactionCount),
  },
  {
    value: 'SPLIT',
    label: translate('movements.splits'),
    color: 'blue',
    variant: 'light',
    count: transactionCount?.splits ?? 0,
  },
  {
    value: 'DEPOSIT',
    label: translate('movements.deposits'),
    color: 'primary',
    variant: 'main',
    count: transactionCount?.deposits ?? 0,
  },
  {
    value: 'REMOVAL',
    label: translate('movements.removals'),
    color: 'error',
    variant: 'light',
    count: transactionCount?.removals ?? 0,
  },
  {
    value: 'TRANSFER_IN',
    label: translate('movements.transfer_in'),
    color: 'success',
    variant: 'light',
    count: transactionCount?.transfersInCount ?? 0,
  },
  {
    value: 'TRANSFER_OUT',
    label: translate('movements.transfer_out'),
    color: 'error',
    variant: 'light',
    count: transactionCount?.transfersOutCount ?? 0,
  },
  {
    value: 'INTEREST,INTEREST_CHARGE',
    label: translate('movements.interest_charge'),
    color: 'info',
    variant: 'light',
    count: getCount(['interestCharge', 'interest'], transactionCount),
  },
  {
    value: 'FEES,DIVIDEND_RETENTION,DIVIDEND_RETENTION_REFUND',
    label: translate('movements.fees'),
    color: 'default',
    variant: 'main',
    count: getCount(
      [
        'fees',
        'dividendRetention',
        'dividendRetentionRefund',
        'capitalGainsRetention',
        'capitalGainsRetentionRefund',
      ],
      transactionCount
    ),
  },
  {
    value: 'TAXES,TAX_REFUND',
    label: translate('movements.taxes'),
    color: 'default',
    variant: 'main',
    count: getCount(['taxes', 'taxRefund'], transactionCount),
  },
  {
    value:
      'GIFT,ISIN_CHANGE,PRODUCT_CHANGE,RIGHTS_ISSUE,RIGHTS_EXCLUSION,RIGHTS_CONVERSION,SPINOFF,UNKNOWN,UNKNOWN_TRANSACTION',
    label: translate('movements.others'),
    color: 'default',
    variant: 'main',
    count: getCount(
      ['gift', 'isinChange', 'productChange', 'rightsIssue', 'rightsExclusion'],
      transactionCount
    ),
  },
  {
    value: 'NOT_CONFIRMED',
    label: translate('movements.not_confirmed'),
    color: 'warning',
    variant: 'main',
    count: transactionCount?.notConfirmed ?? 0,
  },
];

export const typesTab: TabMovementsType[] = [
  {
    value: 'ALL',
    label: 'Todos',
    color: 'default',
    variant: 'main',
    count: 0,
  },
  {
    value: 'STOCK',
    label: 'Acciones',
    color: 'primary',
    variant: 'main',
    count: 0,
  },
  {
    value: 'FUND',
    label: 'Fondos',
    color: 'turquoise',
    variant: 'main',
    count: 0,
  },
  {
    value: 'ETF',
    label: 'ETF',
    color: 'bluePurple',
    variant: 'main',
    count: 0,
  },
  {
    value: 'REIT',
    label: 'REIT',
    color: 'info',
    variant: 'main',
    count: 0,
  },
  {
    value: 'PP',
    label: 'PP',
    color: 'pink',
    variant: 'main',
    count: 0,
  },
  {
    value: 'BOND',
    label: 'Bonos',
    color: 'error',
    variant: 'main',
    count: 0,
  },
];

export const alternativeTypesTabs: TabMovementsType[] = typesTab.slice(0, 1).concat(
  mockData.map(({ id, color, title }) => ({
    color: color as LabelColor,
    value: id,
    label: title,
    variant: 'main',
    count: 0,
  }))
);
