import { IconButton, Stack } from '@mui/material';
import { useContext, useState } from 'react';
// components
import Iconify from '../../Iconify';
import OptionsMenu from './menu/OptionsMenu';
// context
import Context from './context';

// ----------------------------------------------------------------------

type Props = {
  direction: 'asc' | 'desc' | undefined;
  hideOptions?: boolean;
  id: string;
};

export default function IconComponent({ direction, hideOptions, id }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { order, orderBy } = useContext(Context);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  };

  const arrowIcon = `solar:alt-arrow-${order === 'asc' ? 'up' : 'down'}-bold-duotone`;

  return (
    <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
      {orderBy && orderBy === id && (
        <IconButton onClick={handleOpen} sx={{ p: 0.2, ml: 1, mt: 0.2 }}>
          <Iconify color="primary.main" icon={arrowIcon} width={18} />
        </IconButton>
      )}
      {!hideOptions && (
        <IconButton onClick={handleOpen} sx={{ p: 0.2, ml: 2 }}>
          <Iconify icon={'mi:options-horizontal'} width={18} />
        </IconButton>
      )}
      <OptionsMenu id={id} onClose={() => setOpen(null)} open={open} />
    </Stack>
  );
}
